<template>
  <div class="onlive">
    <div class="player">
      <div id="dplayer" ref="dplayer" v-if='videoMuted'/>
      <div class="liveStatus">
        <img src="@/assets/live.gif" alt="">
        直播中</div>
      <div v-if="showVipMask" class="mask">
        <div class="maskTitle">温馨提示</div>
        <div class="desc">开通 <span>直播至尊卡</span> 全站骚货任选~</div>
        <div class="maskBtn" @click="$router.push('/mine/vip')">前往开通</div>
      </div>
      <div v-if="showDownMask" class="mask">
        <div class="maskTitle2">主播 <span>已下播</span> 请稍后观看~</div>
      </div>
    </div>
    <div class="title">
      <div class="userContent">
        <img class="userImg" :src="$route.query.avatarUrl" alt="">
        <div class="userInfo">
          <div>{{ $route.query.username }}</div>
          <div>
            <van-icon name="friends" size="15"/>
            {{ $route.query.viewersCount }}
          </div>
        </div>
      </div>
      <div class='top-right'> 
        <div class="shareBtn" @click="share(URL)">
          <van-icon name="share" size="17"/>
          分享
        </div>
        <img class="closeBtn" src="@/assets/close-btn.png" alt="" @click="close">
      </div>
    </div>
    <div class="top5">
      热门APP
    </div>
    <AdAvatarList class="JGCombtnList"
        v-if="nineListAds && nineListAds.length" :wrap="true" :list="nineListAds" key-name="avatar"
      />
    <!-- <div class="JGbtnList" v-if="nineListAds && nineListAds.length">
      <div class="JGContent isSlide">
        <div v-for="(item, index) in nineListAds" :key="index" @click="JumpFun(item.href)">
          <div>
            <EDecryptImg :imgCDN="imgCDN" :imgURL="item.cover" class="cover"> </EDecryptImg>
          </div>
          <div style="color: #fff">{{ item.name}}</div>
        </div>
      </div>
    </div> -->
    <ERefreshLoadV3 ref="ERefreshLoadV3" :beforeRefresh="refreshData" :moreData="moreData" :finished="finished">
      <template #default="{ data }">
      <div class="top5">
        热门直播
      </div>
      <div class="topic1">
        <LiveTopic4 :list="list"></LiveTopic4>
      </div>

    </template>
  </ERefreshLoadV3>
  </div>
</template>


<script>
import axios from 'axios';
import HlsJsPlayer from 'xgplayer-hls.js';
import { LiveReHome } from '@/api/live.js';
import { JumpTo } from '@/utils/utils_tools';
import LiveTopic4  from '@/components/LiveTv/liveTopic4.vue'
export default {
  data() {
    return {
      nowTime: 0, // 服务器时间
      isVIP: false,
      videoMuted: true,
      xgPlayerRef: null,
      dp: null,
      timer: null,
      showDownMask: false,
      showVipMask: false,
      pageNum: 0,
      pageSize: 10,
      list: [], // 评论列表
      //下拉刷新
      finished: false,
    };
  },
  components:{
    AdAvatarList: () => import('@/components/Ad/AdAvatarList.vue'),
    LiveTopic4
  },
  computed: {
    userInfo({ $store }) {
      return $store.getters['getUserInfo'];
    },
    h5ShareCfgs({$store}) {
      console.log($store.state)
      return $store.state.config.config.h5ShareCfgs;
    },
    URL() {
      // const { domain } = this.h5ShareCfgs;
      return this.$store.getters['h5Cdn'];
    },
    nineListAds({ $store }) {
      return $store.getters['nineListAds'];
    },
   
  },
  watch: {
    nowTime(newArr) {
      let userTime = new Date(this.userInfo.liveVipExpireTime).getTime()
        let nowTimeData = new Date(newArr).getTime()
        if (userTime >= nowTimeData) {
            this.isVIP = true
            this.showVipMask = false
        } else {
          this.isVIP = false
          this.showVipMask = true
        }
    },
  },

  methods: {
    close() {
      this.$router.go(-1);
    },
    async getList() {
      const res = await LiveReHome({
        id: +this.$route.query.id,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      });
      if (res.code === 200) {
        this.nowTime = res.time
        if (!res.data || !res.data.list || res.data.list.length < this.pageSize) {
          this.finished = true;
        }
        if (res.data.list) this.list = [...this.list, ...res.data.list];
      } else {
        this.finished = true;
        return this.$toast(res.tip || res.data);
      }
    },
   //下拉加载
   async moreData() {
      this.pageNum += 1;
      await this.getList();
    },
     //上拉刷新
     refreshData() {
      this.finished = false;
      this.list = [];
      // 一级列表请求参数
      this.pageNum = 0;
    },
    JumpFun(url) {
      JumpTo(url);
    },
    async share(URL) {
      let url = `${URL}?pc=${this.userInfo.inviteCode}`;
      this.$copyText(url).then(() => {
        this.$toast('复制剪贴板成功');
      });
    },

    initPlayer() {
      this.$nextTick(() => {
        if (this.$route.query.stream) {
          this.dp = new HlsJsPlayer({
            id: 'dplayer', //容器ID
            lang: 'zh-cn',
            width: '100%',
            height: '4.16rem',
            autoplayMuted: true,
            autoplay: true, // 是否自动播放
            volume: 0.3, // 音量
            loop: false, // 循环
            pic: true, // 画中画
            miniplayer: false, // 迷你播放
            controls: true,
            cssFullscreen: false,
            videoInit: false, // 初始化视频首帧
            lastPlayTime: 0, // 记忆播放，从多少多少秒开始
            lastPlayTimeHideDelay: 5, //提示文字展示时长（单位：秒）
            rotateFullscreen: false, // 使得视频全屏幕时 横屏
            download: false, //设置download控件显示
            airplay: true,
            playsinline: true,
            // isLive: true, //直播场景设置为true
            // useHls: true,
            preloadTime: 10,
            url:this.$route.query.stream,
            // 开启弹幕
            danmu: {
              closeDefaultBtn: true,
            },
            execBeforePluginsCall: [
              () => {
                console.log('Execute before plugins call');
              },
            ],

            'x5-video-player-type': 'h5', //微信同层播放：
            'x5-video-player-fullscreen': 'true', //微信全屏播放：
            'x5-video-orientation': 'landscape', //微信横竖屏控制
          });

          // 如果预览时长为0，则直接提示
          this.dp.on('play', () => {
            if (!this.isVIP) {
          if (this.dp) {
            // this.dp.exitFullscreen();
            this.dp.pause();
          }
        }
          });
          this.dp.on('error', (error) => {
        this.showDownMask = true;
        this.showVipMask = false;
      });
        }
      });
    },
  },

  mounted() {
    axios(this.$route.query.stream).catch((error) => {
      this.showDownMask = true;
      this.showVipMask = false;
    });
    if (!this.dp) {
      this.initPlayer();
    }
    if (!this.timer) {
      this.timer = setInterval(() => {
        if( this.$refs['ERefreshLoadV3']){
        this.$refs['ERefreshLoadV3'].onRefresh()
      }
      }, 60 * 1000);
    }
  },

  beforeDestroy() {
    if (this.dp) {
      this.dp.destroy();
      this.dp = null;
    }
    clearInterval(this.timer);
    this.timer = null;
  },
};
</script>
<style lang="scss" scoped>
 :deep() {
    .dplayer-full-in-icon:first-child {
      display: none !important;
    }

    .xgplayer-enter-spinner {
      height: 1rem !important;
      width: 1rem !important;
    }

    .dplayer-full-icon:first-child {
      display: none !important;
    }

    .dplayer-mobile-play {
      display: block !important;
    }
    .JGCombtnList{
      .JGContent{
        flex-wrap:nowrap !important;
        >div:nth-child(5n+5){
          margin-right: 0.32rem !important;
        }
      }
    }
  }
.onlive {
  padding-top: 6rem;
  background: #fff;
}

.title{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.16rem;
  position: fixed;
  top: 0px;
  z-index: 99;
  background:#fff;

  .userContent{
    padding: 0.16rem 0.64rem 0.16rem 0.16rem;
    border-radius: 0.84rem;
    // background: rgba(0, 0, 0, 0.16);
    display: flex;
    .userImg{
      width: 0.72rem;
      height: 0.72rem;
      border-radius: 50%;
    }
    .userInfo{
      margin-left: 0.12rem;
      color: #000;
      font-size:0.28rem;
      font-style: normal;
      font-weight: 400;
      >:last-child{
        font-size: 0.2rem;
        line-height: 0.2rem;
        margin-top: 0.06rem;
        color: rgba(0,0,0,0.5);
        display: flex;
      }
    }
  }
  .top-right{
    display: flex;
      align-items: center;
      justify-content: center;
    }
  .shareBtn{

    height: 0.5rem;
    padding: 0.08rem 0.2rem;
    border-radius: .84rem;
    background: rgba(0, 0, 0, 0.2);
    font-size: 0.26rem;
    display:flex;
    margin-right:0.2rem;
    color:#000;
  }
  .closeBtn{
    width:0.38rem;
  }

}
.player{
  width: 100%;
  height: 4.42rem;
  position: fixed;
  top: 1.3rem;
  z-index:120;
  .mask{
    width: 100%;
    height: 100%;
    background:rgba(0,0,0,0.7);
    position:absolute;
    top: 0;
    left: 0;
    z-index: 120;
    font-size: 0.32rem;
    color: #FFF;
    text-align: center;
    line-height: 0.3rem;
    @include flexbox($jc:center,$fd:column);
    >div{
      span{
        color: #FF669D;
      }
    }
    .maskTitle{
      line-height: 0.3rem;
    }
    .maskTitle2{
      line-height: 0.3rem;
    }
    .desc{
      margin: 0.15rem;
    }
    .maskBtn{
      width: 2.5rem;
      display: flex;
      padding: 0.12rem 0.46rem;
      margin:0.1rem auto;
      justify-content: center;
      align-items: center;
      border-radius: 0.64rem;
      background: #FF669D;
      
    }
  }
  .liveStatus{
    position: absolute;
    top:0.5rem;
    right: 0.32rem;
    width: 1.26rem;
    height: 0.48rem;
    border-radius: 0.64rem;
    background: rgba(0, 0, 0, 0.30);
    color: #FFF;
    font-size: 0.22rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
    justify-content: center;
    align-items: center;
    >img{
      margin-right: 0.05rem;
    }
  }
}



.topic1 {
  padding: 0.16rem 0.32rem 0.18rem;
}


.top5 {
  font-size: 0.32rem;
  font-style: normal;
  font-weight: 500;
  color: #000;
  padding: 0.1rem 0.32rem;
  margin: 0 auto;

}

.JGbtnList {
  width: 100%;
  padding: 0.05rem 0.15rem;
  min-height: 0.6rem;
  overflow-x: auto;

  >.JGContent {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    &.wrap {
      flex-wrap: wrap;

      >div {
        &:nth-child(5n + 5) {
          margin-right: 0;
        }
      }
    }

    &.isSlide {
      overflow-x: scroll;
      flex-wrap: nowrap;
    }

    >div {
      overflow: hidden;
      width: 1.2rem;
      margin-right: 0.11rem;
      margin-bottom: 0.12rem;
      flex-shrink: 0;

      >div:first-child {
        border-radius: 0.16rem;
        overflow: hidden;

        .cover {
          width: 1.2rem;
          height: 1.2rem;
        }
      }

      >div:last-child {
        font-size: 0.24rem;
        font-weight: 400;
        text-align: center;
        margin-top: 0.08rem;
        line-height: 0.36rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}
</style>
