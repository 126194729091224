
<template>
 <!-- 列表 -->
 <ERefreshLoadV3 ref="ERefreshLoadV3" :beforeRefresh="refreshData" :moreData="moreData" :finished="finished">
        <div class="top1">
      <img src="@/assets/top1.png" alt="">
    </div>

      <div class="cartoon-list-main-1">
        <img class="title" src="@/assets/title-1.png" alt="">
        <ul>
          <li v-for="(item,index) in list.slice(0, 4)" @click="toPlay(item)" :key="index">
            <img class="cover" :src="item.snapshot">
            </img>
            <div class="user-info">
              <p>{{ item.username }}</p>
              <div class="online">
              <img src="@/assets/online-icon.png" alt="">
              直播中
            </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="cartoon-list-main-2">
        <img class="title" src="@/assets/title-2.png" alt="">
        <ul>
          <li v-for="(item,index) in list.slice(4, 10)" :key="index"  @click="toPlay(item)">
            <img class="cover" :src="item.snapshot">
            </img>
            <div class="online">
              <img src="@/assets/online-icon.png" alt="">
              直播中
            </div>
            <div class="user-info">
              <p>{{ item.username }}</p>

            </div>
          </li>
        </ul>
      </div>
      <div class="cartoon-list-main-3">
        <img class="title" src="@/assets/title-3.png" alt="" >
        <ul >
        <li v-for="(item,index) in list.slice(10, 16)" :key="index" @click="toPlay(item)">
          <img class="cover" :src="item.snapshot">
          </img>
          <div class="online">
            <img src="@/assets/online-icon.png" alt="">
            直播中
          </div>
          <div class="user-info">
            <p>{{ item.username }}</p>
            <div class="counts">
              <img src="@/assets/numbers.png" alt="">
              {{ item.viewersCount }}
            </div>
          </div>
        </li>
      </ul>
      </div>
      <div class="cartoon-list-main-4">
        <img class="title" src="@/assets/title-4.png" alt="">
        <ul >
        <li v-for="(item,index) in list.slice(18)" :key="index"  @click="toPlay(item)">
          <img class="cover" :src="item.snapshot">
          </img>
          <div class="online">
            <img src="@/assets/online-icon.png" alt="">
            直播中
          </div>
          <div class="user-info">
            <p>{{ item.username }}</p>
            <div class="counts">
              <img src="@/assets/numbers.png" alt="">
              {{ item.viewersCount }}
            </div>
          </div>
        </li>
      </ul>
      </div>
    </ERefreshLoadV3>
</template>
<script>
import { JumpTo } from '@/utils/utils_tools';
import { LiveHome } from '@/api/live.js';
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      pageNum: 0,
      pageSize: 10,
      list: [], // 评论列表
      //下拉刷新
      finished: false,

    };
  },
  computed: {
    nineListAds({ $store }) {
      return $store.getters['nineListAds'];
    },
  },
  methods: {
    async getList() {
      const res = await LiveHome({
        id: this.item.data.id,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      });
      if (res.code === 200) {
        if (!res.data || !res.data.list || res.data.list.length < this.pageSize) {
          this.finished = true;
        }
        if (res.data.list) this.list = [...this.list, ...res.data.list];
      } else {
        this.finished = true;
        return this.$toast(res.tip || res.data);
      }
    },
    //下拉加载
    async moreData() {
      this.pageNum += 1;
      await this.getList();
    },
     //上拉刷新
     refreshData() {
      this.finished = false;
      this.list = [];
      // 一级列表请求参数
      this.pageNum = 0;
    },
    JumpFun(url) {
      JumpTo(url);
    },
    toPlay(item) {
      this.$router.push({
        path: '/liveTv/livePlay',
        query: {
          avatarUrl: item.avatarUrl,
          id: item.id,
          isOnline: item.isOnline,
          isRecommended: item.isRecommended,
          snapshot: item.snapshot,
          stream: item.stream,
          username: item.username,
          viewersCount: item.viewersCount,
        },
      });
    },
  },
  activated() {
    this.timer = setInterval(() => {
      if( this.$refs['ERefreshLoadV3']){
        this.$refs['ERefreshLoadV3'].onRefresh()
      }
    }, 60 * 1000);
  },
  deactivated() {
    clearInterval(this.timer);
    this.timer = null;
  },
  beforeDestroy() {
    clearInterval(this.timer);
    this.timer = null;
  },
};
</script>
<style lang="scss" scoped>
.top1 {
  >img {
    width: 100%;
  }
}
.cover{
    object-fit: cover;
}
.cartoon-list-main-1 {
  padding: 0 0.32rem;
text-align: center;
  ul {
    @include flexbox($fw: wrap);
  }

  .title {
    height: 0.77rem;
    // margin: 0.3rem 0;
    margin-top:0.3rem;
  }

  li {
    width: 3.34rem;
    height:2.8rem;
    border-radius: 0.08rem;
    position: relative;
    margin-top:0.28rem;
    color:#fff;
    overflow: hidden;
    .cover {
      position: absolute;
      z-index: 0;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
    border-radius: 0.08rem;

    }
  }
}
.cartoon-list-main-2{
  padding: 0 0.32rem;
  text-align: center;
  ul {
    @include flexbox($fw:wrap);
  }
  .title {
    height: 0.78rem;
    margin: 0.3rem 0;
  }
  li {
    width: 2.18rem;
    height: 2.18rem;
    border-radius: 0.08rem;
    position: relative;
    margin-top: 0.16rem;
    color:#fff;
    overflow: hidden;
  
    .cover {
      position: absolute;
      z-index: 0;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
    border-radius: 0.08rem;

    }
  }
}
.cartoon-list-main-3{
  padding: 0 0.32rem;
  text-align: center;
  ul {
    @include flexbox();
    overflow-y: auto;
  }
  .title {
    height: 0.51rem;
    margin: .3rem 0;
  }
  li {
    width: 5rem;
    height: 3.18rem;
    border-radius: 0.08rem;
    position: relative;
    flex-shrink: 0;
    margin-right: 0.16rem;
    color:#fff;
    overflow: hidden;
    .cover {
      position: absolute;
      z-index: 0;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
    }
  }
}
.cartoon-list-main-4 {
  padding: 0 0.32rem;
  text-align: center;
  .title {
    height: 0.78rem;
    margin: .32rem 0;
  }
  ul{
  @include flexbox($fw: wrap);

  }
  li {
    width:3.36rem;
    height:1.88rem;
    position: relative;
    margin-top: 0.18rem;
    border-radius: 0.08rem;
    color:#fff;
    overflow: hidden;
    .cover {
      position: absolute;
      z-index: 0;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      border-radius: 0.08rem;
    }
  }
}

.online {
  position: absolute;
  @include flexbox($jc: flex-start);
  right: 0.08rem;
  top: 0.13rem;
   font-size: 0.22rem;

  img {
    width: 0.2rem;
    margin-right:0.08rem;
  }
}

.user-info {
  position: absolute;
  left: 0;
  width: 100%;
  bottom: 0;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.9) 100%);
  height: 0.56rem;
  @include flexbox();
  padding: 0.12rem;
  font-size:0.24rem;
  p{
    width: 50%;
    @include textoverflow();
  }
  .counts {
    @include flexbox($jc: flex-start);

    img {
      width: 0.22rem;
      margin-right: 0.04rem;
    }
  }

}
</style>
