<template>
  <div
    class="sort-play home-page-video-style"
    :class="{ 'home-page-video-style': homeSvParams.nowPosit == '首页短视频' }"
  >
    <EBackTop :background="'transparent'" :color="'#fff'" :placeholder="false" />
    <div :id="'short-play'" class="play-box"></div>
    <div class="bg-img">
      <img v-if="!canPlay" src="@/assets/imgs/short-video/short-video-bg.png" alt="" />
    </div>
    <div class="freeTimes" v-if="!isMember">
      免费次数剩余：<span>{{ watchCount }}</span>
    </div>
    <!-- 购买弹窗 -->
    <div class="buy-porp" v-if="buyPorp">
      <!-- 次数不足提示 -->
      <div class="no-times" v-show="code === 6031 || code === 6032">
        <p>免费观看次数已用完</p>
        <p class="sub-txt">您今日的观看次数已经全部用完,开通会员可以无限畅看</p>
        <div class="btn">
          <span @click.stop="$router.push('/mine/agent')">邀请好友</span>
          <span @click.stop="$router.push('/mine/newRecharge?type=1')">开通会员</span>
        </div>
        <div class="tip-txt">
          分享成功可以免费获得1天会员哦 <i @click.stop="$router.push('/mine/agent')">去分享 -</i>
        </div>
      </div>
      <!-- 付费观看 -->
      <div class="no-times" v-show="code === 6033 && !showPayBtn">
        <p>本片需要付费观看</p>
        <p class="sub-txt">本片需要付费观看,请您购买后继续观看</p>
        <div class="btn">
          <span @click.stop="$router.push('/mine/agent')">邀请好友</span>
          <span @click.stop="showPayBtn = true"> {{ itemVideoInfo.price | changeGold }}金币</span>
        </div>
      </div>
      <!-- 支付确认 -->
      <div class="no-times" v-show="showPayBtn && buyPorp">
        <p>确认支付</p>
        <div class="btn">
          <span @click.stop="$router.push('/mine/agent')">邀请好友</span>
          <span @click="payBtnFn"> 支付</span>
        </div>
      </div>
      <!-- 支付确认 -->
      <div class="no-times" v-show="code === 6018">
        <p>您的余额不足以支付本片</p>
        <div class="btn">
          <span @click.stop="$router.push('/mine/agent')">邀请好友</span>
          <span @click.stop="$router.push('/mine/newRecharge?type=0')">去充值</span>
        </div>
      </div>
    </div>
    <!-- 视频轮播 -->
    <div id="ShortVideoPage" ref="ShortVideoPage" class="swiper-container">
      <div class="swiper-wrapper">
        <div
          class="swiper-slide"
          v-for="(item, index) in virtualData.slides"
          :key="index"
          :style="{
            top: `${virtualData.offset}px`,
            backgroundColor: slideChangeTransition ? '#000' : 'transparent'
          }"
        >
          <div class="slide-mark" @click="handleClickSlideMark">
            <transition name="van-fade">
              <div class="volume-tip" v-if="!showVolume" @click.stop="clickCloseVolume">
                <van-icon name="volume-o" />
                <span>解除静音</span>
              </div>
            </transition>
            <div class="right-icon">
              <!-- <div class="right-icon-header" @click.stop="clickImg(itemVideoInfo)">
                <EDecryptImg :imgCDN="imgCDN" :imgURL="itemVideoInfo.publisher ? itemVideoInfo.publisher.avatar : ''">
                </EDecryptImg>
                <img
                  @click.stop="careUser(itemVideoInfo.publisher)"
                  v-if="!itemVideoInfo.publisher.isFollow"
                  class="add-icon"
                  src="@/assets/imgs/short-video/addCare.png"
                  alt=""
                />
              </div> -->
              <div class="right-icon-likes" v-if="!showAll">
                <img
                  @click.stop="addLike(itemVideoInfo, 2)"
                  v-if="!itemVideoInfo.isLike"
                  src="@/assets/imgs/upHome/likeIconS.png"
                  alt=""
                />
                <img @click.stop="addLike(itemVideoInfo, 2)" v-else src="@/assets/imgs/upHome/likeIconX.png" alt="" />
                <span>{{ itemVideoInfo.likes | numberFilter }}</span>
              </div>
              <div class="right-icon-likes" @click.stop="comments_fn" v-if="!showAll">
                <van-icon name="chat" size="0.5rem" />
                <div>评论</div>
                <!-- <img
                  @click.stop="addLike(itemVideoInfo, 3)"
                  v-if="!itemVideoInfo.isStampede"
                  src="@/assets/imgs/upHome/weakIconS.png"
                  alt=""
                />
                <img @click.stop="addLike(itemVideoInfo, 3)" v-else src="@/assets/imgs/upHome/weakIconX.png" alt="" />
                <span>{{ itemVideoInfo.stampede | numberFilter }}</span> -->
              </div>
              <div class="right-icon-likes" v-if="!showAll">
                <img
                  @click.stop="addLike(itemVideoInfo, 1)"
                  v-if="!itemVideoInfo.isCollect"
                  src="@/assets/imgs/upHome/collectIconS.png"
                  alt=""
                />
                <img
                  @click.stop="addLike(itemVideoInfo, 1)"
                  v-else
                  src="@/assets/imgs/upHome/collectIconX.png"
                  alt=""
                />
                <span>{{ itemVideoInfo.collects | numberFilter }}</span>
              </div>
              <div class="right-icon-share" @click.stop="$router.push('/mine/agent')" v-if="!showAll">
                <img src="@/assets/imgs/upHome/shareIconS.png" alt="" />
                <span>分享</span>
              </div>
              <div class="right-icon-likes" @click.stop="showAll = !showAll">
                <van-icon name="eye" size="0.5rem" color="#fff" />
                {{ showAll ? '展开' : '隐藏' }}
              </div>
            </div>
            <div class="video-info" v-if="!showAll">
              <!-- 标签 -->
              <div class="tags-list">
                <span @click.stop="toTagList(sitem)" v-for="sitem in itemVideoInfo.tags" :key="sitem"
                  >#{{ sitem }}</span
                >
              </div>

              <div class="video-title">
                {{ itemVideoInfo.title }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 评论弹窗 -->
    <JavComment
      :showComment="showCommentPop"
      :objectype="1"
      @close="close"
      :publisher="itemVideoInfo.publisher.id"
      :objectId="itemVideoInfo.id"
    />
  </div>
</template>

<script>
import { video_play, video_pay } from 'api/play'
import { collect, care_add, homeRecommendList, homeFocusList, homeFeaturedList } from 'api/home'
import { throttle } from '@/utils/utils_tools'
import { media_details, index_home } from 'api/home' // topic详情列表
import { collect_list } from 'api/user' // 收藏记录列表
import { pay_history } from 'api/user' // 购买记录列表
import { user_media } from 'api/community' // up小视频列表
import { search, tag_detail } from 'api/search' // 搜索视频列表// 分类标签列表

const HlsJsPlayer = require('xgplayer-hls.js')
export default {
  layout: 'noComponent',
  props: {
    homeSvParams: {
      type: Object,
      default() {
        return {
          nowPosit: ''
        }
      }
    }
  },
  data() {
    return {
      showCommentPop: false,
      pageNum: 1,
      pageSize: 10,
      list: [],
      canPlay: false,
      code: 0, // 视频code
      playable: false, // 视频能否播放
      activeIndex: 0, // 接收到的默认播放数据索引
      swiper: null,
      showVolume: false, // 控制静音按钮
      slideChangeTransition: false, // 轮播变化时
      throttle: null, // 节流函数
      buyPorp: false, // 购买弹窗
      showPayBtn: false, //购买确认弹窗控制
      itemVideoInfo: {
        publisher: {}
      }, //当前播放视频信息
      virtualData: {
        slides: []
      },
      showAll: false,
      controlsDOM: '', // 控制台
      player: '', // 播放器实例
      watchCount: 0 // 免费观看册书
    }
  },
  components: {
    JavComment: () => import('@/components/JavComment.vue')
  },
  computed: {
    imgCDN({ $store }) {
      return $store.getters['cdn']
    },
    // 用户余额
    user({ $store }) {
      return $store.getters['getUserInfo']
    },
    isMember({ $store }) {
      return $store.getters['isMember']
    },
    token({ $store }) {
      return $store.state.user.token;
    },
  },
  methods: {
    // 评论弹窗事件
    comments_fn() {
      this.showCommentPop = true
    },
    // 评论弹窗关闭
    close(show) {
      this.showCommentPop = show
    },
    // 首页推荐小视频
    async getHomeRecommendList() {
      try {
        let res = {}
        if (this.homeSvParams.name == '推荐' || this.$route.params.id === '推荐') {
          res = await homeRecommendList({
            pageNum: this.pageNum,
            pageSize: this.pageSize
          })
        } else if (this.homeSvParams.name == '关注') {
          res = await homeFocusList({
            pageNum: this.pageNum,
            pageSize: this.pageSize
          })
        } else if (this.homeSvParams.name == '热门') {
          res = await homeFeaturedList({
            pageNum: this.pageNum,
            pageSize: this.pageSize
          })
        }
        if (res.code === 200) {
          if (res.data.shortList && res.data.shortList.length) {
            this.list = this.remove_repeat(this.list, res.data.shortList)
          } else {
            this.list = this.remove_repeat(this.list, res.data.mediaList)
          }
          this.concatSlide(this.list)
        } else {
          this.$toast(res.tip)
        }
      } catch (error) {
        console.log(error)
        this.$toast('请求失败，请稍后再试！')
      }
    },
    // 短视频详情列表 首页推荐+推荐页详情跳转 参数 id, sort 默认0
    async getTopicDetails() {
      try {
        const res = await media_details({
          id: +this.$route.params.id,
          sort: +this.$route.query.sort || 0,
          pageNum: this.pageNum,
          pageSize: this.pageSize
        })
        if (res.code === 200) {
          this.list = [...this.list, ...res.data.mediaList]
          this.concatSlide(this.list)
        } else {
          this.$toast(res.tip)
        }
      } catch (error) {
        console.log(error)
        this.$toast('请求失败，请稍后再试！')
      }
    },
    // 获取首页默认短视频列表数据
    async getHomePage() {
      try {
        const res = await index_home({
          id: +this.$route.params.id,
          sort: +this.$route.query.sort || 0,
          pageNum: this.pageNum,
          pageSize: this.pageSize
        })
        if (res.code === 200) {
          if (res.data.shortList && res.data.shortList.length) {
            this.list = this.remove_repeat(this.list, res.data.shortList)
          } else {
            this.list = this.remove_repeat(this.list, res.data.mediaList)
          }
          this.concatSlide(this.list)
        } else {
          this.$toast(res.tip)
        }
      } catch (error) {
        console.log(error)
        this.$toast('请求失败，请稍后再试！')
      }
    },
    //去重
    remove_repeat(oldlist, newlist) {
      // console.log(oldlist,newlist)
      let newShortArr = []
      newlist.forEach(newEle => {
        let status = false
        oldlist.forEach(oldEle => {
          if (newEle.id == oldEle.id) {
            status = true
          }
        })
        if (!status) newShortArr.push(newEle)
      })
      newShortArr = [...oldlist, ...newShortArr]
      return newShortArr
    },
    // 获取历史记录短视频列表
    getHistoryList() {
      this.list = JSON.parse(this.$store.getters['getShortVideoList'])
    },
    // 获取收藏短视频列表
    async getCollect() {
      try {
        const res = await collect_list({
          type: 2,
          pageNum: this.pageNum,
          pageSize: this.pageSize
        })
        if (res.code === 200) {
          this.list = [...this.list, ...res.data.mediaList]
        } else {
          this.$toast(res.tip)
        }
      } catch (error) {
        console.log(error)
        this.$toast('请求错误，请稍后再试！')
      }
    },
    // 购买记录短视频列表
    async getPayList() {
      try {
        const res = await pay_history({
          type: 2,
          pageNum: this.pageNum,
          pageSize: this.pageSize
        })
        if (res.code === 200) {
          this.list = [...this.list, ...res.data.mediaList]
        } else {
          this.$toast(res.tip)
        }
      } catch (error) {
        console.log(error)
        this.$toast('请求错误，请稍后再试！')
      }
    },
    // 搜索短视频列表
    async getSearchList() {
      try {
        const res = await search({
          content: this.$route.params.id,
          type: 2,
          pageNum: this.pageNum,
          pageSize: this.pageSize
        })
        if (res.code === 200) {
          this.list = [...this.list, ...res.data.mediaList]
        } else {
          this.$toast(res.tip)
        }
      } catch (error) {
        console.log(error)
        this.$toast('请求错误，请稍后再试！')
      }
    },
    // up主短视频列表
    async getUpList() {
      try {
        const res = await user_media({
          userId: +this.$route.params.id,
          pageSize: this.pageSize,
          pageNum: this.pageNum
        })
        if (res.code === 200) {
          this.list = [...this.list, ...res.data.mediaList]
        } else {
          this.$toast(res.tip)
        }
      } catch (error) {
        console.log(error)
        this.$toast('请求错误，请稍后再试！')
      }
    },
    // 分类短视频列表
    async getTagList() {
      try {
        const res = await tag_detail({
          coverType: +this.$route.query.coverType,
          sort: +this.$route.query.sort,
          coded: +this.$route.query.coded,
          location: +this.$route.params.id,
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          tag: this.$route.query.tag
        })
        if (res.code === 200) {
          this.list = [...this.list, ...res.data.mediaList]
        } else {
          this.$toast(res.tip)
        }
      } catch (error) {
        console.log(error)
        this.$toast('请求错误，请稍后再试！')
      }
    },

    // 点击观看完整视频
    showBuyPorp() {
      this.player.pause()
      this.buyPorp = true
    },
    // 解除静音
    clickCloseVolume() {
      if (this.player) {
        this.showVolume = true
        this.player.muted = false
      }
    },
    // up主跳转 头像
    clickImg(item) {
      if (item.publisher && item.publisher.id) {
        this.$router.push(`/up/index/${item.publisher.id}`)
      } else {
        this.$toast('发布者id不存在！')
      }
    },
    // 剪贴板
    doCopy(text) {
      this.$copyText(text).then(() => {
        this.$toast('复制剪贴板成功')
      })
    },
    // 点击分享按钮
    shareBtn(options, index) {
      switch (index) {
        case 0:
          let url = this.$store.getters['shareUrl'] + '/'
          if (url[url.length - 1] == '/') {
            url = url.slice(0, url.length - 1)
          }

          let str = url + '/#' + '/short_video/play' + '?detailId=' + this.itemVideoInfo.id + '&' + 'typeTxt=分享'
          this.doCopy(str)
          break
        default:
          this.$router.push({ path: '/mine/agent' })
          break
      }
    },
    // 视频购买成功
    async payBtnFn() {
      // 先判断当前余额
      if (this.user.balance < this.itemVideoInfo.price) {
        this.code = 6018
        this.showPayBtn = false
        return
      }
      try {
        const res = await video_pay({
          id: this.itemVideoInfo.id,
          payType: 1
        })
        if (res.data.code === 200) {
          this.$toast('解锁成功,正在重新加载...')
          this.playable = true
          this.code = 200
          this.showPayBtn = false
          this.initPlay()
          await this.$store.dispatch('get_userInfo')
        } else {
          this.code = res.data.code
          this.$toast(res.tip)
        }
        this.buyPorp = false
      } catch (error) {
        console.log(error)
        this.buyPorp = false
        this.$toast('请求错误，请稍后再试！')
      }
    },
    // 关注，取消关注按钮
    async careUser(info) {
      if (this.user.loginType == 0) {
        return this.$toast('仅注册的用户才能关注')
      }
      // 关注用户
      try {
        const res = await care_add({
          id: info.id,
          add: !info.isFollow,
          type: 1
        })
        if (res.code === 200) {
          info.isFollow = !info.isFollow
          if (info.isFollow) {
            return this.$toast('关注成功')
          } else {
            return this.$toast('取消关注')
          }
        } else {
          return this.$toast(res.tip)
        }
      } catch (error) {
        console.log(error)
        return this.$toast('请求失败，请稍后再试')
      }
    },
    // 视频标签跳转
    toTagList(item) {
      this.$router.push({
        path: `/hot_tag/detail/${2}`,
        query: { name: item }
      })
    },
    /**
     * 初始化轮播图
     **/
    initSwiper() {
      const that = this
      this.swiper = new Swiper('.swiper-container', {
        direction: 'vertical',
        initialSlide: that.historyShortVideoIndex, //初始化时第一个显示的slide        roundLengths : true, //防止文字模糊
        roundLengths: true, //防止文字模糊
        runCallbacksOnInit: false,
        followFinger: false,
        // longSwipes: false,
        virtual: {
          cache: true,
          slides: that.list,
          renderExternal: data => {
            // 在渲染虚拟slider时 取获取到具体数据
            that.virtualData = data
          }
        },
        on: {
          // 初始化后播放视频，并且请求下一页数据
          init() {
            that.$nextTick(() => {
              that.initPlay()
            })
            that.$store.dispatch('setShortList', {
              type: 'add',
              item: that.list[that.activeIndex]
            })
          },
          touchEnd(swiper, event) {
            if (swiper.swipeDirection === 'next' && that.activeIndex + 1 === that.list.length) {
              that.$toast('别滑啦，人家已经是最后一个了...')
            }
          },
          slideChangeTransitionEnd() {
            that.slideChangeTransition = false
            that.$nextTick(() => {
              that.throttle()
            })
          },
          slideChangeTransitionStart(swiper) {
            that.slideChangeTransition = true
            if (that.controlsDOM) {
              that.controlsDOM.classList.add('controlHide')
            }
          },
          slidePrevTransitionStart() {
            --that.activeIndex
          },
          async slideNextTransitionStart() {
            // 每次切换都触发这个方法，请求下一页数据
            ++that.activeIndex
            // 根据传入typeTXT判断请求哪个列表
            that.$store.dispatch('setShortList', {
              type: 'add',
              item: that.list[that.activeIndex]
            })
            if (that.activeIndex + 1 === that.list.length) {
              that.pageNum += 1
              if (that.homeSvParams.nowPosit == '首页短视频') {
                await that.getHomeRecommendList()
              } else {
                await that.getTypeTxtQuest(that.$route.query.typeTxt)
              }
            }
          }
        }
      })
    },
    // 切换数组
    concatSlide(dspList) {
      if (this.swiper) {
        this.swiper.virtual.slides = dspList
        this.swiper.update()
      }
    },
    // 根据传入typeTXT判断请求哪个列表
    async getTypeTxtQuest(typeTxt) {
      switch (typeTxt) {
        case '推荐': //小视频首页列表+小视频更多：传tab栏id，
        case '详情':
          await this.getTopicDetails()
          break
        case '默认': //传tab栏id，sort(排序非必须)，
          await this.getHomePage()
          break
        case '观看记录':
          await this.getHistoryList()
          break
        case '收藏记录':
          await this.getCollect()
          break
        case '购买记录':
          await this.getPayList()
          break
        case 'up':
          await this.getUpList()
          break
        case '搜索':
          await this.getSearchList()
          break
        case '分类':
          await this.getTagList()
          break
        default:
          break
      }
    },
    // 初始化播放器
    async initPlay() {
      const that = this
      // 每次播放做一次请求，获取播放状态
      let videoInfo = this.list[this.activeIndex]
      if (this.player) {
        this.player.destroy(true)
        this.player = null
      }
      this.player = new HlsJsPlayer({
        id: `short-play`, //容器ID
        lang: 'zh-cn',
        autoplay: true, // 是否自动播放
        volume: 0.3, // 音量
        autoplayMuted: true,
        width: window.width,
        height: window.innerHeight,
        closeInactive: true, //使播放器控制栏常驻不隐藏
        loop: false, // 循环
        pic: false, // 画中画
        miniplayer: false, // 迷你播放
        miniplayerConfig: {
          bottom: 40,
          right: 0,
          width: 160,
          height: 90
        },
        // controls: true,
        controlsList: ['nofullscreen'],
        fullscreen: false,
        cssFullscreen: false,
        videoInit: false, // 初始化视频首帧
        lastPlayTime: 0, // 记忆播放，从多少多少秒开始
        lastPlayTimeHideDelay: 5, //提示文字展示时长（单位：秒）
        rotateFullscreen: false, // 使得视频全屏幕时 横屏
        download: false, //设置download控件显示
        airplay: false,
        playsinline: true,
        url: '/api/app/media/h5/m3u8/' + videoInfo.videoUrl + `?token=${this.token}`,
        execBeforePluginsCall: [
          () => {
            console.log('Execute before plugins call')
          }
        ],
        // poster: "http://lf9-cdn-tos.bytecdntp.com/cdn/expire-1-M/byted-player-videos/1.0.0/poster.jpg",
        'x5-video-player-type': 'h5', //微信同层播放：
        'x5-video-player-fullscreen': 'true', //微信全屏播放：
        'x5-video-orientation': 'landscape' //微信横竖屏控制
      })
      // 显示播放loading
      if (this.player) this.player.play()

      this.controlsDOM = document.getElementsByClassName('xgplayer-controls')
      if (this.controlsDOM && this.controlsDOM.length) {
        this.controlsDOM = this.controlsDOM[0]
      }
      this.player.once('canplay', () => {
        this.player.play()
      })
      this.player.on('canplay', function () {
        that.canPlay = true
      })
      // 获取当前视频播放时间
      this.player.on('timeupdate', function () {
        // 当不可播放完整视频时，如果播放时长大于等于预览市场，给出购买提示
        if (that.itemVideoInfo.preTime == 0) {
          if ((!that.playable || that.code !== 200) && that.player.currentTime >= 15) {
            that.buyPorp = true
            that.player.pause()
            that.player.currentTime = 0
          }
        } else {
          if ((!that.playable || that.code !== 200) && that.player.currentTime >= that.itemVideoInfo.preTime) {
            that.buyPorp = true
            that.player.pause()
            that.player.currentTime = 0
          }
        }
      })
    },

    // 点击slide里最高层级时的逻辑
    handleClickSlideMark() {
      let playPromise = this.player.play()
      if (playPromise) {
        playPromise
          .then(() => {
            if (this.player && this.player.hasStart) {
              if (this.player.paused) {
                this.player.play()
              } else {
                this.player.pause()
              }
            }
          })
          .catch(() => {
            this.player.pause()
          })
      } else {
        return
      }
    },
    // 请求视频播放信息
    async getVideoInfo(id) {
      try {
        const res = await video_play({
          id
        })
        if (res.code === 200) {
          this.code = res.data.code
          this.playable = res.data.playable
          this.itemVideoInfo = res.data.mediaInfo
          this.watchCount = res.data.watchCount
        } else {
          this.$notify('视频已下架或播放错误，请选择其他视频观看!')
        }
      } catch (error) {
        console.log(error)
        this.$notify('视频已下架或播放错误，请选择其他视频观看!')
      }
    },
    // 收藏，喜欢视频
    async addLike(item, type) {
      const res = await collect({
        collectType: item.videoType,
        flag: type == 1 ? !item.isCollect : type == 2 ? !item.isLike : !item.isStampede,
        object_id: item.id,
        type: type //1:收藏 2:点赞 3:踩
      })
      if (res.code === 200) {
        const typeMap = {
          1: { property: 'isCollect', counter: 'collects', successMsg: '收藏成功', failMsg: '收藏取消' },
          2: { property: 'isLike', counter: 'likes', successMsg: '点赞成功', failMsg: '点赞取消' },
          3: { property: 'isStampede', counter: 'stampede', successMsg: '踩踏成功', failMsg: '踩踏取消' }
        }
        if (typeMap.hasOwnProperty(type)) {
          const { property, counter, successMsg, failMsg } = typeMap[type]
          item[property] = !item[property]
          if (this.itemVideoInfo[property]) {
            item[counter] += 1
            return this.$toast(successMsg)
          } else {
            item[counter] -= 1
            return this.$toast(failMsg)
          }
        }
      } else {
        return this.$toast(res.tip)
      }
    }
  },
  async mounted() {
    //首页短视频（等待接口返回再操作）
    if (this.homeSvParams.nowPosit == '首页短视频' || this.$route.params.id == '推荐') {
      await this.getHomeRecommendList()
      this.activeIndex = 0
      await this.getVideoInfo(this.list[this.activeIndex].id)
    } else {
      //其他地方短视频
      await this.getVideoInfo(+this.$route.query.detailId)
      this.list = this.remove_repeat(this.list, [this.itemVideoInfo])
      this.activeIndex = 0
      this.getTypeTxtQuest(this.$route.query.typeTxt)
    }
    if (this.list.length === 0) {
      return this.$toast('暂无视频')
    }
    this.$nextTick(() => {
      // 注册节流方法
      this.throttle = throttle(async () => {
        // 获取当前slide视频节点
        this.player.currentTime = 0
        let videoInfo = this.list[this.activeIndex]
        //第一次不更新
        if (this.list.length > 1) {
          this.getVideoInfo(videoInfo.id)
        }
        //切换
        this.player.src = '/api/app/media/h5/m3u8/' + videoInfo.videoUrl  + `?token=${this.token}`
        this.canPlay = false
        // 为了保证更快速播放，这里主动调用，其实不调用也可以播放，但是他更快速
        let playPromise = this.player.play()
        playPromise
          .then(() => {
            this.player.play()
          })
          .catch(() => {
            return
          })
        // 取消隐藏控制台
        if (this.controlsDOM) {
          this.controlsDOM.classList.remove('controlHide')
        }
      }, 100)
      if (this.imgCDN) {
        this.initSwiper()
      }
    })
  },
  watch: {
    imgCDN() {
      this.$nextTick(() => {
        this.initSwiper()
      })
    }
  },

  beforeDestroy() {
    this.list[this.activeIndex].currentTime = this.player.currentTime
    this.$store.dispatch('setShortList', {
      type: 'add',
      item: this.list[this.activeIndex]
    })

    this.swiper = null
    this.throttle = null
    if (this.player) {
      if (this.player.hls) {
        this.player.hls.destroy()
      }
      this.player.src = ''
      this.player.destroy(true)
    }
  }
}
</script>
<style scoped src="../../../static/css/swiper.min.css"></style>
<style lang="scss" scoped>
.newPop {
  color: #000000;
  p {
    font-size: 0.32rem;
    text-align: center;
    font-weight: 500;
  }
  .sub-txt {
    font-size: 0.24rem;
    font-weight: 400;
  }
  .btn {
    @include flexbox($jc: space-between, $ai: center, $fd: row, $fw: nowrap);
    margin: 0.3rem;
    justify-content: center;
    div {
      width: 1.8rem;
      height: 0.56rem;
      background: $btnBg;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 15px;
      font-size: 0.28rem;
      color: #fff;
      margin-left: 0.3rem;
      margin-right: 0.3rem;
    }
  }
  .tip-txt {
    font-size: 0.24rem;
    text-align: center;
  }
}
:deep() {
  //普通播放页
  .xgplayer-progress-outer {
    margin-top: 0;
    height: 3px;
    margin-top: 8.5px;
  }
  .xgplayer-skin-default .xgplayer-controls {
    height: 0;
    position: fixed;
    // bottom: calc(1.1rem + env(safe-area-inset-bottom));
    // bottom: calc(1.1rem + constant(safe-area-inset-bottom));
  }
}
//首页，专用内置样式
.home-page-video-style {
  :deep() {
    .xgplayer-skin-default .xgplayer-controls {
      height: 0;
      position: fixed;
      bottom: calc(0.7rem + env(safe-area-inset-bottom));
      bottom: calc(0.7rem + constant(safe-area-inset-bottom));
    }
    .xgplayer-placeholder,
    .xgplayer-volume,
    .xgplayer-play,
    .xgplayer-fullscreen,
    .xgplayer-volume {
      display: none;
    }
    .xgplayer-skin-default .xgplayer-progress {
      margin: 0 auto;
      width: 4.32rem;
      top: auto;
      height: 0.4rem;
      line-height: 0.4rem;
      position: fixed;
      bottom: calc(0.5rem + constant(safe-area-inset-bottom));
      bottom: calc(0.5rem + env(safe-area-inset-bottom));
    }
    .xgplayer-progress-outer {
      margin-top: 0;
      height: 3px;
      margin-top: 8.5px;
    }
    .xgplayer-icon {
      .xgplayer-icon-play,
      .xgplayer-icon-muted,
      .xgplayer-icon-pause {
        display: none !important;
      }
    }
    .xgplayer-fullscreen,
    .xgplayer-enter {
      display: none !important;
    }
    .xgplayer-time {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      max-width: $pcMaxWidth;
      height: 0;
      margin: auto;
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 0 0.5rem;
      align-items: center;
      span {
        &:nth-child(1) {
        }
        &:nth-child(2) {
        }
        &::after {
          display: none;
          content: none;
        }
      }
    }
  }
}
.sort-play {
  padding: 0;
}
#short-play {
  :deep() {
    .xgplayer-enter-spinner {
      width: 1rem;
      height: 1rem;
    }
  }
}
.swiper-container {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  max-width: $pcMaxWidth;
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  font-size: 0.18rem;
  color: #fff;
  /* Center slide text vertically */
}
.bg-img {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  background: #000;
  overflow: hidden;
  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
}
.slide-mark {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 999;
}

.volume-tip {
  position: absolute;
  display: flex;
  align-items: center;
  z-index: 1;
  left: 0.2rem;
  top: 2rem;
  padding: 0 0.15rem;
  height: 0.5rem;
  line-height: 0.5rem;
  border-radius: 0.2rem;
  background-color: rgba($color: #dbd1d1, $alpha: 0.65);
  color: rgb(121, 23, 23);
  font-size: 0.22rem;
}
:deep() {
  video {
    position: absolute;
    top: 0;
    z-index: 1;
  }
  .xgplayer-skin-default {
    background-color: transparent;
  }
  .controlHide {
    visibility: hidden;
    opacity: 0;
  }
  #ShortVideoPage {
    position: fixed;
    top: 0;
  }
}
// 右侧三个图标
.right-icon {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: absolute;
  right: 0.2rem;
  bottom: 3.8rem;
  z-index: 999;
  font-size: 0.24rem;
  &-header {
    @include flexbox($jc: center, $ai: center, $fd: column, $fw: nowrap);
    margin-bottom: 0.6rem;
    position: relative;
    width: 0.7rem;
    height: 0.7rem;
    :deep() {
      .warp {
        border-radius: 50%;
      }
      img {
        border-radius: 50%;
      }
    }
    .add-icon {
      position: absolute;
      width: 0.25rem;
      height: 0.25rem;
      bottom: -0.12rem;
      left: 50%;
      transform: translate(-50%, 0);
    }
  }

  &-comment,
  &-share,
  &-likes {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 0.2rem;
    img {
      width: 0.48rem;
      height: 0.48rem;
    }
  }
}

// 短视频描述
.video-info {
  position: absolute;
  left: 0.2rem;
  bottom: calc(1.6rem + constant(safe-area-inset-bottom));
  bottom: calc(1.6rem + env(safe-area-inset-bottom));
  max-width: 5.2rem;
  display: flex;
  flex-direction: column;
  font-size: 0.24rem;
  z-index: 2;
  .publisher-info {
    display: flex;
    align-items: center;
    .pay-btn {
      margin-left: 0.1rem;
      height: 0.4rem;
      background: $btnBg;
      color: #fff;
      border-radius: 0.2rem;
      padding: 0.1rem 0.15rem;
      display: flex;
      align-items: center;
      white-space: nowrap;
      .price {
        display: flex;
        align-items: center;
        font-size: 0.3rem;
        padding-right: 0.06rem;
        img {
          width: 0.24rem;
          height: 0.24rem;
          margin: 0 0.1rem;
        }
      }
    }
  }
  .video-title {
    text-align: left;
  }
  .tags-list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 0.16rem;
    span {
      padding: 0.02rem 0.13rem;
      background: rgba($color: #000, $alpha: 0.3);
      margin-right: 0.15rem;
      margin-top: 0.15rem;
      display: inline-block;
      border-radius: 0.08rem;
    }
  }
}
// 已关注标签
// .isFollow {

//   padding: 0.03rem 0.15rem;
//   border: 0.02rem solid #fff;
//   border-radius: 0.1rem;
//   margin-top: 0.2rem;
//    color: #755542;
// }
.desc {
  font-size: 0.28rem;
}
.freeTimes {
  line-height: 0.54rem;
  padding: 0 0.16rem;
  border-radius: 0.12rem;
  background: rgba(0, 0, 0, 0.6);
  font-size: 0.24rem;
  color: #fff;
  position: fixed;
  top: 0.46rem;
  right: 0.32rem;
  z-index: 2012;
  span {
    color: #ff1e1e;
  }
}
// 购买提示框
.buy-porp {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 1000;
  max-width: $pcMaxWidth;
  background: rgba($color: #000, $alpha: 0.8);
  @include flexbox($jc: center, $ai: center, $fd: column, $fw: nowrap);
  color: #fff;
  font-size: 0.32rem;
  .no-times {
    text-align: center;
    z-index: 9999;
    .btn {
      @include flexbox($jc: space-between, $ai: center, $fd: row, $fw: nowrap);
      max-width: 4rem;
      margin: 0 auto;
    }
    span:first-child {
      background: #ff9900;
      color: #000;
      margin-right: 0.2rem;
    }
    span {
      width: 1.8rem;
      line-height: 0.56rem;
      font-size: 0.28rem;
      border-radius: 0.32rem;
    }
    span:last-child {
      margin-left: 0.2rem;
      background: $btnBg;
      color: #fff;
    }
    .sub-txt {
      color: rgba($color: #fff, $alpha: 0.5);
      font-size: 0.24rem;
      margin: 0.26rem 0;
    }
    .tip-txt {
      font-size: 0.2rem;
      margin-top: 0.2rem;
      i {
        margin-left: 0.1rem;
        color: $btnBg;
        display: inline-block;
        font-style: normal;
      }
    }
  }
}
// 金币购买弹窗
</style>
