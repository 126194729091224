<template>
  <div class="onlive">
    <div class="icon-box">
      <van-icon class="back-icon" name="arrow-left" color="#000" size="32" @click.stop="$router.go(-1)" />
    </div>
    <ETabBar :list="liveCategory" :tabbarConfig="tabbarConfig" :activeNum="activeId" >
      <template v-slot:default="props">
        <component ref="homeIndexDefault" :item="props" :is="compComponent(props.data.showType)"></component>
      </template>
    </ETabBar>
  </div>
</template>

<script>
import liveTvIndex from '@/components/LiveTv/LiveTvIndex.vue';
import liveTvDefault from '@/components/LiveTv/LiveTvDefault.vue';

export default {
  components: {
    liveTvIndex,
    liveTvDefault,
  },
  data() {
    return {
      timer: null,
      activeId: null,
      tabbarConfig: {
        barWidth: '7.5rem',
        fontSize: '0.3rem', //默认字体大小
        fontSizeActive: '0.3rem', //选中字体大小
        fontColor: '#000', //默认字体颜色
        fontColorActive: '#FF669D', //选中字体颜色
        bg: '#fff', //默认背景色
        bgActive: '#fff', //选中背景色
        lineBott: '0.06rem', //下划线距离底部距离 String
        lineWidth: '0.08rem', //下划线宽度 String
        lineHeight: '.2rem', //下划线高度 String
        lineColorActive: '#FF669D',
        sticky: true, //粘性布局
        tabFlex: true, //左侧收缩布局
        offsetTop: '0rem', //距顶部距离
      },
    };
  },
  computed: {
    liveCategory({ $store }) {
      this.activeId = $store.state.config.config.liveCategory[0].id || 0;
      return $store.state.config.config.liveCategory;
    },
  },
  methods: {
    compComponent(data) {
      switch (data) {
        case 1:
          return liveTvIndex;
        default:
          return liveTvDefault;
      }
    },

  },

};
</script>
<style lang='scss' scoped>
:deep() {
  .van-tabs__wrap {
    padding-left: 1rem;
  }
    .van-tabs__line{
      width:0.5rem !important;
      height:0.1rem !important;
    }
    
    .van-tab{
      font-size:0.36rem !important;
    }
    .van-tab--active{
      .van-tab{
        font-weight: 600;
      }
    }
}

.onlive {
  background: #fff;
}

.icon-box {
  position: fixed;
  top: 0px;
  z-index: 100;
  font-size: 0.3rem;
  background: #fff;
  left: 0px;
  width: 1rem;
  height: 1rem;
  @include flexbox($jc: center);
}
</style>
