<template>
  <div class="four">
    <div class="four-main">
      <div class="four-card" v-for="(item, index) in list" :key="index" :data="item">
        <div class="list-card" @click="toPlay(item)">
          <div class='point'>
            <img src="@/assets/online-icon.png" alt="">
                直播中
              </div>
          <div class="cover">
            <img class="bg" :src="item.snapshot"></img>
            <div class="time">
              <div>
                <div>{{ item.username }}</div>
                <div>
                  <van-icon name="friends-o" size="15"/>
                  {{ item.viewersCount }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },

  },
  methods: {
    closeMask(item, index, list) {
      this.$emit('closeMask', item, index, list);
    },
    toPlay(item) {
      this.$router.replace({
        path: '/liveTv/livePlay',
        query: {
          avatarUrl: item.avatarUrl,
          id: item.id,
          isOnline: item.isOnline,
          isRecommended: item.isRecommended,
          snapshot: item.snapshot,
          stream: item.stream,
          username: item.username,
          viewersCount: item.viewersCount,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
// 五宫格样式表
.four-main {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .four-card {
    width:3.36rem;
    display: inline-block;
    margin-bottom: 0.28rem;

    //overflow: hidden;
    .showMask-wrap {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.5);
      z-index: 2;

      >img {
        position: absolute;
        top: 0.2rem;
        right: 0.2rem;
        width:0.5rem;
      }
    }
  }
}

.list-card {
  width: 100%;
  position: relative;
  min-height: 1.96rem;
  .point {
    display: flex;
    align-items: center;
    position: absolute;
    top: 0.16rem;
    right: 0.16rem;
    z-index: 1;
    color: #fff;
    font-size: 0.22rem;
   
    >img {
      width: 0.2rem;
      margin-right: 0.1rem;
    }
  }
}

.cover {
  position: relative;
  overflow: hidden;
  border-radius: 0.16rem;
  width: 3.36rem;
  height: 2rem;

  .bg {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}



.time {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 8%, rgba(0, 0, 0, 0.6) 100%);
  height: 0.31rem;
  position: absolute;
  bottom: 0;
  width: 100%;
  color: #fff;
  font-size: 0.22rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.2rem;
  font-weight: 400;

  >div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    >img {
      width: 0.22rem;
      margin-right: 0.1rem;

    }
  }

}
</style>
