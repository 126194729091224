<template>
 <!-- 列表 -->
 <ERefreshLoadV3 ref="ERefreshLoadV3" :beforeRefresh="refreshData" :moreData="moreData" :finished="finished">
      <!-- <div class="JGbtnList" v-if="nineListAds && nineListAds.length">
        <div class="JGContent isSlide" >
          <div v-for="(item, index) in nineListAds" :key="index" @click="JumpFun(item.download_url)">
            <div>
              <DecryptImg :imgUrl="item.avatar" class="cover"> </DecryptImg>
            </div>
            <div style="color: #fff">{{ item.name}}</div>
          </div>
        </div>
      </div> -->
    <div :style="{ maxHeight: adShow ? 'none' : '180px' }" class="overflow-hidden">
      <AdAvatarList
        v-if="nineListAds && nineListAds.length" :wrap="true" :list="nineListAds" key-name="avatar"
      />
    </div>
    <!-- <div class="ad-open " @click="adShow = !adShow">
      <div class="h-18 w-43.5 flex-center rd-t-4 bg-[rgba(43,37,70,0.5)] text-10 color-#fff">
        <img
          class="mr-2 w-10" :style="{ transform: adShow ? 'rotateX(180deg)' : '' }" src="@/assets/ad-show-icon.png" alt=""
          srcset=""
        >{{ adShow ? '收起' : '展开' }}
      </div>
    </div> -->

    <div class="top5">
      热门直播
    </div>
      <ul class="cartoon-list-main">
        <li v-for="item in list" :key="item.id"  @click="toPlay(item)">
          <img class="cover" :src="item.snapshot">
          </img>
          <div class="online">
            <img src="@/assets/online-icon.png" alt="">
            直播中
          </div>
          <div class="user-info">
            <p>{{ item.username }}</p>
            <div class="counts">
              <img src="@/assets/numbers.png" alt="">
              {{ item.viewersCount }}
            </div>
          </div>
        </li>
      </ul>
    </ERefreshLoadV3>

</template>

<script>
import { JumpTo } from '@/utils/utils_tools';
import { LiveHome } from '@/api/live.js';
export default {
  components: {
    AdAvatarList: () => import('@/components/Ad/AdAvatarList.vue')
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      pageNum: 0,
      pageSize: 10,
      list: [], // 评论列表
      //下拉刷新
      finished: false,
      adShow:false
    };
  },
  computed: {
    nineListAds({ $store }) {
      return $store.getters['nineListAds'];
    },
  },
  methods: {
    async getList() {
      const res = await LiveHome({
        id: this.item.data.id,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      });
      if (res.code === 200) {
        if (!res.data || !res.data.list || res.data.list.length < this.pageSize) {
          this.finished = true;
        }
        if (res.data.list) this.list = [...this.list, ...res.data.list];
      } else {
        this.finished = true;
        return this.$toast(res.tip || res.data);
      }
    },
    //下拉加载
    async moreData() {
      this.pageNum += 1;
      await this.getList();
    },
     //上拉刷新
     refreshData() {
      this.finished = false;
      this.list = [];
      // 一级列表请求参数
      this.pageNum = 0;
    },
    JumpFun(url) {
      JumpTo(url);
    },
    toPlay(item) {
      this.$router.push({
        path: '/liveTv/livePlay',
        query: {
          avatarUrl: item.avatarUrl,
          id: item.id,
          isOnline: item.isOnline,
          isRecommended: item.isRecommended,
          snapshot: item.snapshot,
          stream: item.stream,
          username: item.username,
          viewersCount: item.viewersCount,
        },
      });
    },
  },
  activated() {
    this.timer = setInterval(() => {
      if( this.$refs['ERefreshLoadV3']){
        this.$refs['ERefreshLoadV3'].onRefresh()
      }
    }, 60 * 1000);
  },
  deactivated() {
    clearInterval(this.timer);
    this.timer = null;
  },
  beforeDestroy() {
    clearInterval(this.timer);
    this.timer = null;
  },
};
</script>

<style lang="scss" scoped>
.ad-open{
  margin-top:0.2rem;
  display: flex;
    align-items: center;
    justify-content: center;
    border-bottom:0.02rem solid #2B254680;
    >div{
      height:0.36rem;
      background-color:rgba(43,37,70,0.5);
      color:#fff;
      border-top-left-radius:0.08rem;
      border-top-right-radius:0.08rem;
      font-size:0.2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      width:0.87rem;
    }
    img{
      margin-right:0.04rem;
      width:0.2rem
    }
}
.top5 {
font-size:0.32rem;
font-style: normal;
font-weight: 500;
color: #000;
padding: 0.1rem 0.32rem;
padding-bottom: 0;

}
.cover{
  object-fit: cover;
}
.cartoon-list-main {
padding: 0 0.32rem;
@include flexbox($fw: wrap);
overflow-y: auto;
li {
  width: 3.26rem;
  height: 1.88rem;
  border-radius: 0.04rem;
  position: relative;
  margin-top: 0.3rem;
  color:#fff;
  overflow: hidden;
  .cover {
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    border-radius: 0.08rem;
  }
}
}

.online {
position: absolute;
@include flexbox($jc: flex-start);
right: 0.08rem;
top: 0.13rem;
 font-size: 0.22rem;

img {
  width: 0.2rem;
  margin-right:0.08rem;
}
}

.user-info {
position: absolute;
left: 0;
width: 100%;
bottom: 0;
background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.9) 100%);
height: 0.56rem;
@include flexbox();
padding: 0.12rem;
font-size:0.24rem;
p{
  width: 50%;
  @include textoverflow();
}
.counts {
  @include flexbox($jc: flex-start);

  img {
    width: 0.22rem;
    margin-right: 0.08rem;
  }
}

}
.JGbtnList {
    width: 100%;
    padding: 0.05rem 0.15rem;
    min-height: 0.6rem;
    overflow-x: auto;
    margin-top: 0.2rem;
    >.JGContent {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      &.wrap {
        flex-wrap: wrap;

        >div {
          &:nth-child(5n + 5) {
            margin-right: 0;
          }
        }
      }

      &.isSlide {
        overflow-x: scroll;
        flex-wrap: nowrap;
      }

      >div {
        overflow: hidden;
        width: 1.2rem;
        margin-right: 0.11rem;
        margin-bottom: 0.12rem;
        flex-shrink: 0;

        >div:first-child {
          border-radius: 0.16rem;
          overflow: hidden;

          .cover {
            width: 1.2rem;
            height: 1.2rem;
          }
        }

        >div:last-child {
          font-size: 0.24rem;
          font-weight: 400;
          text-align: center;
          margin-top: 0.08rem;
          line-height: 0.36rem;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }
</style>
