var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home-index",class:`${_vm.from && _vm.from === 'home' ? 'home-index-from2' : 'home-index-from1'}`},[_c('PullUp',{attrs:{"finished":_vm.finished,"loading":_vm.loading,"refreshing":_vm.refreshing},on:{"refreshData":_vm.refreshData,"moreData":_vm.moreData}},[(_vm.navItem.item.adsType === 2 && _vm.iconHomeAds.length)?_c('div',{staticClass:"top_banner"},[_c('AdAvatarList',{attrs:{"list":_vm.iconHomeAds,"wrap":true,"key-name":"avatar"}})],1):(_vm.navItem.item.adsType !== 3 && _vm.avAD.length)?_c('div',{staticClass:"top_banner"},[_c('EADSwiper',{attrs:{"EADconfig":_vm.EADconfig,"imgCDN":_vm.imgCDN,"list":_vm.avAD,"keyName":"cover"},on:{"clickImg":_vm.activityBtn}})],1):_vm._e(),(_vm.runningLight)?_c('van-notice-bar',{attrs:{"left-icon":"volume","color":"#000","background":"rgb(238, 238, 238, 0.6)","text":_vm.runningLight?.content},on:{"click":function($event){return _vm.toInfo(_vm.runningLight)}}}):_vm._e(),(_vm.activityAds.length > 1)?_c('div',{staticClass:"swiper-ad"},[_c('EADSwiper',{staticClass:"ad-list2",attrs:{"centeredSlides":_vm.activityAds.length > 1,"list":_vm.activityAds,"keyName":"img","EADconfig":_vm.EADconfig,"imgCDN":_vm.imgCDN,"activeIndex":2,"slidesPerView":2,"spaceBetween":20},on:{"clickImgBetter":_vm.activityBtn2}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.maskFlag),expression:"maskFlag"}],staticClass:"mask_ad"})],1):_vm._e(),_c('div',{staticClass:"tab-main"},[_c('div',{staticClass:"home-index-main"},_vm._l((_vm.topicList),function(item,index){return _c('div',{key:item.id},[_c('div',{staticClass:"topPack"},[_c('div',{staticClass:"topL"},[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"topR",on:{"click":function($event){return _vm.$router.push({
                  path: `/subpage/morePage`,
                  query: {
                    id: item.id,
                    name: item.name,
                    type: item.showType
                  }
                })}}},[_vm._v(" 更多"),_c('van-icon',{attrs:{"name":"arrow"}})],1)]),_c(_vm.compComponent(item.showType),{tag:"component",attrs:{"list":item.mediaList,"showType":item.showType,"name":item.name,"id":item.id}})],1)}),0)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }